<template>
  <FullHeightSdCardWrapper>
    <a-row justify="center" :gutter="25">
      <a-col :xs="24">
        <Suspense>
          <template #default>
            <sdCards title="Order History">
              <a-row>
                <a-col :md="12" :xs="24">
                  <TimeLinePointerIconWrap>
                    <a-timeline>
                      <a-timeline-item v-for="history in histories" :key="history.histories" class="timeline-primary">
                        <template #dot>
                          <SwRocket size="20" color="#2C99FF" />
                        </template>
                        <h3>{{ dateFormat(history.created_at) }}</h3>
                        <p>{{ history.comment }}</p>
                        <span class="tags">{{ history.status }}</span>
                      </a-timeline-item>
                    </a-timeline>
                  </TimeLinePointerIconWrap>
                </a-col>
                <a-col :md="12" :xs="24">
                  <!-- header nav's height -->
                  <a-affix :offset-top="70">
                    <BasicFormWrapper>
                      <a-form layout="vertical" class="mb-20">
                        <a-form-item label="New Comment">
                          <a-textarea v-model:value="comment" :auto-size="{ minRows: 3 }" :disabled="saving" />
                        </a-form-item>
                      </a-form>
                      <sdButton type="primary" @click="createComments" :disabled="saving">Submit</sdButton>
                    </BasicFormWrapper>
                  </a-affix>
                </a-col>
              </a-row>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>
  </FullHeightSdCardWrapper>
</template>

<script>
import { FullHeightSdCardWrapper, BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { TimeLinePointerIconWrap } from './style';
import { dateFormat } from '@/utility/filter';
import { SwRocket } from '@/components/utilities/icons';
import { useRoute } from 'vue-router';

export default {
  name: 'OrderHistory',
  components: {
    FullHeightSdCardWrapper,
    TimeLinePointerIconWrap,
    SwRocket,
    BasicFormWrapper,
  },
  setup() {
    const { state, dispatch } = useStore();
    const histories = computed(() => state?.orders?.order?.status_histories ?? []);
    const status = computed(() => state?.orders?.order?.status);

    const saving = computed(() => state.orders.saving);

    const { params } = useRoute();
    const comment = ref('');
    const createComments = async () => {
      const payload = {
        statusHistory: {
          comment: comment.value,
          is_customer_notified: 0,
          is_visible_on_front: 0,
          parent_id: 0,
          extension_attributes: {},
          status: status.value,
        },
      };

      await dispatch('createOrderComment', {
        entityId: params.id,
        payload,
      });

      comment.value = '';
    };

    return {
      saving,
      comment,
      histories,
      dateFormat,
      createComments,
    };
  },
};
</script>
