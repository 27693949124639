<template>
  <sdCards title="Open Carts">
    <!-- <template #button>
      <sdButton class="btn-signin" type="warning" @click="goRefundPage">Credit Memo</sdButton>
    </template> -->
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <TableWrapper class="table-responsive">
            <a-table
              :loading="isLoading"
              :dataSource="tableData"
              :columns="tableColumns"
              :pagination="{
                current,
                defaultPageSize: 12,
                total: totalCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }"
            />
          </TableWrapper>
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { FileCardWrap } from './style';
import { TableWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Active',
    dataIndex: 'is_active',
    key: 'is_active',
  },
  {
    title: 'Customer Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    key: 'lastname',
  },
];
export default {
  components: {
    FileCardWrap,
    TableWrapper,
  },
  setup() {
    const { state, dispatch } = useStore();
    const invoices = computed(() => state.orders.openCarts);
    const isLoading = computed(() => state.orders.loading);
    const totalCount = computed(() => state.orders.totalCount);
    const current = computed(() => state.orders.currentPage);
    const pageSize = computed(() => state.orders.pageSize);

    const handleTableChange = async (pagination) => {
      state.orders.currentPage = pagination.current;
      await dispatch('fetchOpenCarts');
    };

    onMounted(async () => {
      await dispatch('fetchOpenCarts');
    });

    const tableData = computed(() =>
      invoices.value.length
        ? invoices.value.map((item) => {
            const { entity_id, increment_id, updated_at } = item;

            // const to = {
            //   name: 'omni-order-invoice-view-create',
            //   params: { id: order.value.entity_id, invoiceId: entity_id },
            // };

            return {
              id: entity_id,
              active: increment_id,
              email: updated_at,
              firstname: entity_id,
              // lastname: order.value.updated_at,
              // bill_to_name: order.value.billing_address.firstname + ' ' + order.value.billing_address.lastname,
              // status: state === 2 ? 'paid' : 'not paid',
              // amount: grand_total,
              // action: (
              //   <div class="table-actions">
              //     <>
              //       <router-link to={to}>
              //         <sdButton class="btn-icon" type="default" shape="circle">
              //           <sdFeatherIcons type="edit" size="16" />
              //         </sdButton>
              //       </router-link>
              //     </>
              //   </div>
              // ),
            };
          })
        : [],
    );

    return {
      tableData,
      tableColumns,
      isLoading,
      current,
      totalCount,
      pageSize,
      handleTableChange,
    };
  },
};
</script>
