<template>
  <sdCards :title="`Payment Information`">
    <a-row v-if="isLoading" justify="center">
      <a-spin class="mb-25" :style="{ minHeight: '300px', display: 'flex', alignItems: 'center' }"></a-spin>
    </a-row>
    <div v-if="!isLoading && paymentData">
      <p>{{ paymentData }}</p>
    </div>
    <div v-if="!isLoading && !Object.entries(paymentData).length" class="mb-25">No payment ID</div>
  </sdCards>
</template>
<script>
// import { FileCardWrap } from './shipments/style';
// import { TableWrapper } from '@/view/styled';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DataService } from '@/config/dataService/dataService';
import { currencyFromCode } from '@/utility/filter';
const tableColumns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id',
  // },
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
  },
  // {
  //   title: 'Parent Transaction ID',
  //   dataIndex: 'parent_transaction_id',
  //   key: 'parent_transaction_id',
  // },
  {
    title: 'Payment Method',
    dataIndex: 'payment_method',
    key: 'payment_method',
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type',
    key: 'transaction_type',
  },
  {
    title: 'Closed',
    dataIndex: 'closed',
    key: 'closed',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

export default {
  name: 'OrderTransactionsList',
  setup() {
    const { state, commit } = useStore();
    const transactions = computed(() => state.orders.transactions);
    const transaction = computed(() => state.orders.transaction);
    const isLoading = ref(() => false);
    const paymentData = computed(() => {
      const data = state.orders.thirdPartyPaymentInfo;
      console.log(data, 'payment provider data');
      const paymentProvider = state.auth?.user?.store_views[0].payment_provider;
      if (paymentProvider === 'stripe') {
        return {
          Amount: currencyFromCode(data.currency) + String(data.amount / 100),
          Currency: data.currency,
          PaymentMethod: data.payment_method_types[0],
          Status: data.status,
        };
      } else {
        return data;
      }
    });
    const paymentProvider = state.auth?.user?.store_views[0].payment_provider
      ? state.auth?.user?.store_views[0].payment_provider
      : state.orders.order?.payment?.method;

    const onDelete = (id) => {
      console.log('deleting ----', id);
    };

    const tableData = computed(() =>
      transactions.value
        ? transactions.value.map((transaction) => {
            const { order_id, transaction_id, payment_id, txn_type, is_closed, created_at } = transaction;

            const to = {
              name: 'omni-order-shipment-view',
              params: { id: order_id, shipmentId: transaction_id },
            };

            return {
              key: transaction_id,
              order_id,
              transaction_id,
              payment_method: payment_id,
              transaction_type: txn_type,
              closed: is_closed ? 'Yes' : 'No',
              created: created_at,
              action: (
                <div class="table-actions">
                  <>
                    <router-link to={to}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="edit" size="16" />
                      </sdButton>
                    </router-link>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => onDelete(transaction_id)}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    onMounted(async () => {
      isLoading.value = true;
      const activeStoreView = state.auth.activeStoreView;
      let paymentProvider = state?.orders?.order?.payment.method;
      if (paymentProvider === 'cnpayment') {
        paymentProvider = activeStoreView?.default_provider;
      }
      const paymentIntent = state?.orders?.order?.extension_attributes?.payment_additional_info.find(
        (info) => info.key === 'paymentID',
      );

      if (paymentProvider === 'barclayspayment') {
        // const paymentIntentId = '3178223043';
        if (paymentIntent?.value) {
          let data = await DataService.post(`${activeStoreView.code}/barclays/transaction`, {
            transaction_reference: paymentIntent.value,
            trans_no: '',
          });
          var parser, xmlDoc;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(data.data, 'text/xml');

          let auth = xmlDoc.getElementsByTagName('status')[0].childNodes[0].nodeValue;
          let cardtype = xmlDoc.getElementsByTagName('name')[0].childNodes[0].nodeValue;
          let desc = xmlDoc.getElementsByTagName('purchaseDescription')[0].childNodes[0].nodeValue;
          commit('setThirdPartyPaymentInfo', auth + cardtype + desc);
        }
      } else if (paymentProvider === 'stripe') {
        if (paymentIntent?.value) {
          const {
            data: { data, status },
          } = await DataService.post(`${activeStoreView.code}/stripe/transaction`, {
            payment_id: paymentIntent.value,
          });

          if (status === 'success') {
            commit('setThirdPartyPaymentInfo', data);
            // paymentData.value = data && data['@attributes'];
          } else {
            isLoading.value = false;
            commit('setThirdPartyPaymentInfo', false);
          }
        }
      } else if (paymentProvider === 'paypal') {
        if (paymentIntent?.value) {
          const {
            data: { data, status },
          } = await DataService.post(`${activeStoreView.code}/paypal/transaction`, {
            payment_id: paymentIntent.value,
          });

          if (status === 'success') {
            commit('setThirdPartyPaymentInfo', data);
            // paymentData.value = data && data['@attributes'];
          } else {
            isLoading.value = false;
            commit('setThirdPartyPaymentInfo', false);
          }
        }
      } else if (paymentProvider === 'checkoutcom') {
        if (paymentIntent?.value) {
          const {
            data: { data, status },
          } = await DataService.post(`${activeStoreView.code}/checkoutcom/getPaymentDetails`, {
            id: paymentIntent.value,
          });

          if (status === 'success') {
            commit('setThirdPartyPaymentInfo', data);
            // paymentData.value = data && data['@attributes'];
          } else {
            isLoading.value = false;
            commit('setThirdPartyPaymentInfo', false);
          }
        }
      } else {
        if (state.orders.order?.payment?.method === 'cybersourcepayment' && paymentIntent?.value) {
          const {
            data: { data, status },
          } = await DataService.get(`${activeStoreView.code}/cybersource/transaction/${paymentIntent.value}`, {
            payment_id: paymentIntent.value.value,
          });

          if (status === 'success') {
            commit('setThirdPartyPaymentInfo', data[0]);
            // paymentData.value = data && data['@attributes'];
          } else {
            isLoading.value = false;
            commit('setThirdPartyPaymentInfo', false);
          }
        }
      }
      isLoading.value = false;
    });

    return {
      tableColumns,
      tableData,
      transactions,
      transaction,
      isLoading,
      paymentData,
      paymentProvider,
    };
  },
};
</script>
